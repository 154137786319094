<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path d="M5.36849 6.52315L2.03516 8.18982L8.46331 11.4039C8.55076 11.4476 8.59449 11.4695 8.64036 11.4781C8.68098 11.4857 8.72267 11.4857 8.76329 11.4781C8.80915 11.4695 8.85288 11.4476 8.94034 11.4039L15.3685 8.18982L12.0352 6.52315M5.36849 9.85649L2.03516 11.5232L8.46331 14.7372C8.55076 14.781 8.59449 14.8028 8.64036 14.8114C8.68098 14.819 8.72267 14.819 8.76329 14.8114C8.80915 14.8028 8.85288 14.781 8.94034 14.7372L15.3685 11.5232L12.0352 9.85649M2.03516 4.85649L8.46331 1.64241C8.55076 1.59868 8.59449 1.57682 8.64036 1.56822C8.68098 1.56059 8.72267 1.56059 8.76329 1.56822C8.80916 1.57682 8.85288 1.59868 8.94034 1.64241L15.3685 4.85649L8.94034 8.07056C8.85288 8.11429 8.80916 8.13616 8.76329 8.14476C8.72267 8.15238 8.68098 8.15238 8.64036 8.14476C8.59449 8.13616 8.55076 8.11429 8.46331 8.07056L2.03516 4.85649Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconLayers"
}
</script>
